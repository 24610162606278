<template>
  <mi-select
    ref="chips"
    :model-value="combination.preCondition || []"
    outlined
    use-input
    :error="combination.invalid"
    :error-message="combination.message"
    :placeholder="combination.preCondition?.length > 0 ? '' : 'eg.: AA001;BB001;BB002;'"
    multiple
    hide-dropdown-icon
    :label="customLabelShow ? customLabel : `Precondition ${ index +1 }`"
    class="col q-ml-sm"
    :rules="choicesCombinationsInputRules"
    @new-value="(val, done) => createValue(val, combination, index, done)"
    @input-value="(val) => onChange(val, index)"
  >
    <template #selected-item="scope">
      <q-chip
        removable
        dense
        :tabindex="scope.tabindex"
        color="white"
        text-color="secondary"
        class="q-mr-md"
        :class="invalidChoices.has(scope.opt) ? 'q-chip-error' : 'q-chip-success'"
        @remove="removeValue(scope, combination, index)"
      >
        {{ scope.opt }}
      </q-chip>
    </template>
    <template v-if="showTooltip" #append>
      <product-model-settings-tooltip></product-model-settings-tooltip>
    </template>
  </mi-select>
</template>

<script>
  import { nextTick } from 'vue'
  import { containsSpecialChars } from '@/utils/validators/common'
  import ProductModelSettingsTooltip from '../../product-model/settings/ProductModelSettingsTooltip.vue'

  export default {
    name: 'ChoicesCombinations',
    components: { ProductModelSettingsTooltip },
    props: {
      customLabel: {
        type: String,
        required: false,
        default: ''
      },
      customLabelShow: {
        type: Boolean,
        required: false,
        default: false
      },
      combination: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      addChoices: {
        type: Function,
        required: false,
        default: () => {}
      },
      removeChoice: {
        type: Function,
        required: false,
        default: () => {}
      },
      updateInvalidValue: {
        type: Function,
        required: false,
        default: () => {}
      },
      invalidChoices: {
        type: Set,
        required: false,
        default: () => new Set()
      },
      showTooltip: {
        type: Boolean,
        default: true,
        required: false
      },
      choicesCombinationsInputRules: {
        type: Array,
        default: () => [],
        required: false
      }
    },
    setup(props) {
      const isComponentLimitReached = (preconditions, values) => preconditions.length
        + values.length > 20

      const validateSpecialChars = ({ selectedValue = '', settingIndex }) => {
        const hasSpecialChar = containsSpecialChars(selectedValue)

        if (hasSpecialChar) {
          props.updateInvalidValue(settingIndex, 'message', 'The separator is not allowed')
          return true
        }

        return false
      }

      const validateMaxChoicesLimitReached = ({ preconditions = [], values = [], settingIndex }) => {
        const isLimitReached = isComponentLimitReached(preconditions, values)

        if (isLimitReached) {
          props.updateInvalidValue(settingIndex, 'message', 'The maximum number of choices is 20')
          return true
        }
        return false
      }

      const removeValue = (val, setting, settingIndex) => {
        const maxLimitReached = validateMaxChoicesLimitReached({ preconditions: setting.preCondition, settingIndex })
        props.updateInvalidValue(settingIndex, 'invalid', maxLimitReached)

        props.removeChoice(val, settingIndex)
      }

      const createValue = (val, setting, settingIndex, done) => {
        const preconditions = setting?.preCondition || []
        const valueList = val.toUpperCase().split(';').filter(item => !!item)

        const invalidChars = validateSpecialChars({ selectedValue: val, settingIndex })
        const maxLimitReached = validateMaxChoicesLimitReached({ preconditions, values: valueList, settingIndex })

        props.updateInvalidValue(settingIndex, 'invalid', invalidChars || maxLimitReached)

        if (invalidChars || maxLimitReached) return

        valueList.map(v => v.trim())
          .filter(v => v.length > 0 && v.length < 6)
          .forEach(v => preconditions.push(v))

        const updatedConditions = [...new Set(preconditions)]

        nextTick(() => {
          done?.([...updatedConditions].pop())
        })

        props.addChoices(updatedConditions, settingIndex)
      }

      const onChange = (value, settingIndex) => {
        if (!value) props.updateInvalidValue(settingIndex, 'invalid', false)
      }

      return {
        createValue,
        removeValue,
        onChange
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.q-field) {
    // stylelint-disable declaration-no-important
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ::v-deep(.q-field__append) {
    height: 0 !important;
    color: $mi-anthracite-800;
    cursor: pointer;
  }

  ::v-deep(.q-field__bottom) {
    padding: 2px !important;
  }

  ::v-deep(.q-field__bottom--stale) {
    transform: translateY(100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ::v-deep(.q-chip--dense .q-chip__icon) {
    font-size: 9px;
    padding: 2px;
    background-color: $mi-anthracite-800;
    border-radius: 50%;
    color: $mi-white;
    margin-left: 4px;
  }

  ::v-deep(.q-chip--dense .q-chip__icon--remove) {
    background-color: $mi-anthracite-800;
    opacity: 1;
  }

  ::v-deep(.q-chip) {
    margin-right: 0;
  }

  ::v-deep(.q-chip__content) {
    font-size: 12px;
    font-weight: 800;
    color: $mi-anthracite-800;
  }

  .q-chip-success {
    border: 2px solid $mi-anthracite-800 !important;
  }

  .q-chip-error {
    border: 2px solid $mi-red-700 !important;
    background-color: $mi-red-50 !important;

    ::v-deep(.q-chip__content) {
      color: $mi-red-700;
    }

    ::v-deep(.q-icon) {
      background-color: $mi-red-700 !important;
    }
  }
</style>
